import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import { useToast } from 'react-native-toast-notifications';

import BoxSelector from '../components/BoxSelector';
import { BoxSelectorTypes } from '../Structures';
import { listAvailableDeviceTypes } from '../services/http/Requests';
import { showErrorToast } from '../helpers';
import { renderLogic } from '../components/Loading';

const DeviceType = forwardRef((props, ref) => {

    const toast = useToast();
    const [devicesData, setDevicesData] = useState([]);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function getDevicesTypes() {
            const devices = await listAvailableDeviceTypes();
            setDevicesData(devices);
            setIsLoading(false);
        }

        getDevicesTypes();
    }, []);

    useImperativeHandle(ref, () => ({

        async nextHandler() {
            if (selectedDeviceIndex == -1) {
                showErrorToast(toast, 'Selecciona el tipo de dispositivo', '');
                return;
            }

            return props.onNextSubmit();
        }
    }));

    const body = (
        <View style={styles.container}>
            {devicesData.map((device, index) => {
                return (
                    <BoxSelector
                        key={index}
                        isSelected={selectedDeviceIndex == index}
                        selectorType={BoxSelectorTypes.Image}
                        brandName={device.name}
                        image={device.image_1920}
                        onPress={() => {
                            setSelectedDeviceIndex(index);
                            props.onSelectedDeviceType(devicesData[index]);
                        }}
                    />
                )
            })}
        </View>
    );

    return renderLogic(isLoading, body);
});

const styles = {
    container: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        //backgroundColor: 'red',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default DeviceType;
