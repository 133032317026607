import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import { useToast } from 'react-native-toast-notifications';

import { listAvailableModels } from '../services/http/Requests';

import RadioInput from '../components/RadioInput';
import { showErrorToast } from '../helpers';
import { renderLogic } from '../components/Loading';


const ModelCard = ({ modelName, selected, onPress }) => {

    const cardStyle = {
        width: '80%',
        maxWidth: 500,
        height: 50,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#A8A8A8',
        backgroundColor: '#fff',
        margin: 10,
        alignItems: 'center',
        justifyContent: 'center'
    }

    return (
        <Pressable style={cardStyle} onPress={onPress}>
            <RadioInput isSelected={selected} top={17} left={20} />
            <Text style={{ fontSize: 18 }}>{modelName}</Text>
        </Pressable>
    )
}

const DeviceModel = forwardRef((props, ref) => {

    const toast = useToast();
    const [modelsData, setModelsData] = useState([]);
    const [selectedModelIndex, setSelectedModelIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);

    

    useEffect(() => {

        async function getModels() {
            const devices = await listAvailableModels(props.selectedBrandID);

            setModelsData(devices);
            setIsLoading(false);
        }
        
        getModels();
    }, []);

    useImperativeHandle(ref, () => ({

        async nextHandler() {
            if (selectedModelIndex == -1) {
                showErrorToast(toast, 'Selecciona tu modelo', 'Selecciona el modelo de tu dispositivo');
                return;
            }

            return props.onNextSubmit();
        }
    }))

    const body = (
        <View style={styles.container}>
            {modelsData.map((model, index) => {
                return (
                    <ModelCard
                        key={index}
                        modelName={model.name}
                        selected={selectedModelIndex == index}
                        onPress={() => {
                            setSelectedModelIndex(index);
                            props.onSelectedModel(modelsData[index])
                        }}
                    />
                )
            })}
        </View>
    )

    return renderLogic(isLoading, body);
});

const styles = {
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default DeviceModel;
