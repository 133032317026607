
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { render } from 'react-dom';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import { useToast } from "react-native-toast-notifications";

import {
    listAvailableDaysForRepairment
} from '../services/http/Requests';


import RadioInput from '../components/RadioInput';
import { showErrorToast } from '../helpers';
import { renderLogic } from '../components/Loading';

const Card = ({ label, onPress, isSelected }) => {
    return (
        <Pressable style={{ marginVertical: 5 }} onPress={onPress}>
            <View style={{ height: 50, width: 270, borderWidth: 1, borderColor: '#A8A8A8', backgroundColor: '#fff', borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
                <RadioInput isSelected={isSelected} />
                <Text>{label}</Text>
            </View>
        </Pressable>
    )
}

const DaySelector = forwardRef((props, ref) => {

    const toast = useToast();
    const [availableDays, setAvailableDays] = useState([]);
    const [selectedDayIndex, setSelectedDayIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function listAvailableDays() {
            const days = await listAvailableDaysForRepairment();
            
            setAvailableDays(days);
            setIsLoading(false);
        }

        listAvailableDays();
    }, []);

    useImperativeHandle(ref, () => ({

        async nextHandler() {
           if(selectedDayIndex == -1) {
               showErrorToast(toast, 'Selecciona un día', 'Selecciona el día en el que quieras que reparemos tu equipo');
               return;
           }

           return props.onNextSubmit();
        }
    }))

    function renderDays() {
        return availableDays.map((day, i) => {
            return (
                <Card
                    key={i}
                    label={day.day_of_week}
                    isSelected={selectedDayIndex == i}
                    onPress={() => {
                        props.onSelectedDayTimestamp(day.timestamp);
                        setSelectedDayIndex(i);
                    }}                 
                />
            )
        })
    }

    const body = (
        <View style={styles.container}>
            {renderDays()}
        </View>
    )

    return renderLogic(isLoading, body);
});

const styles = {
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default DaySelector;
