import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Picker } from "react-native";
import { without } from 'lodash';
import { useToast } from 'react-native-toast-notifications';

import HintBox from '../components/HintBox';
import CheckInput from '../components/CheckInput';
import { showErrorToast } from '../helpers';
import { renderLogic } from '../components/Loading';

import { listPartsForModelID } from '../services/http/Requests';
import { ODOO_HOST } from '../config';

import { PRIMARY_COLOR } from '../colors/colors';

const ModelCard = ({ repairName, repairPrice, selected, onPress }) => {

    const cardStyle = {
        width: '80%',
        maxWidth: 500,
        minHeight: 50,
        padding: 5,
        //height: 50,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#A8A8A8',
        backgroundColor: '#fff',
        margin: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingHorizontal: 20
    }

    return (
        <Pressable style={cardStyle} onPress={onPress}>
            <View style={{ flex: 0.5 }}>
                <CheckInput isSelected={selected} top={17} left={20} />
            </View>
            <View style={{ flex: 2 }}>
                <Text style={{ fontSize: 17, marginHorizontal: 5 }}>{repairName}</Text>
            </View>
            <View style={{ flex: 1.1, alignItems: 'flex-end', minWidth: 65 }}>
                <Text style={{ fontSize: 17 }}>{repairPrice}</Text>
            </View>
        </Pressable>
    )
}

export const DeviceRepairs = forwardRef((props, ref) => {

    const toast = useToast();
    const [selectionStatuses, setSelectionStatuses] = useState([]);
    const [partsData, setPartsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedRepairIDS, setSelectedRepairsIDS] = useState([]);

    useEffect(() => {

        async function getRepairs() {
            const repairs = await listPartsForModelID(props.selectedModelID);
            setSelectionStatuses(new Array(repairs.length + 1).fill(false));
            setPartsData(repairs);
            setIsLoading(false);
        }

        getRepairs();
    }, []);

    useImperativeHandle(ref, () => ({

        async nextHandler() {

            if (selectionStatuses[selectionStatuses.length - 1]) {
                window.open(`${ODOO_HOST}/helpdesk/atencion-al-cliente-1?contact_form=1`);
                return;
            }

            if (selectionStatuses.filter(s => s == true).length == 0) {
                showErrorToast(toast, 'Selecciona una reparación', 'Selecciona una reparación para tu dispositivo');
                return;
            }

            return props.onNextSubmit(selectedRepairIDS);
        }
    }))

    function hasProductVariants(variants, template_name) {
        return !(variants.length == 1 && variants[0].display_name == template_name);
    }

    function renderVariantPicker(repair) {

        if (repair?.variants?.length == 0) {
            return <></>;
        }

        // Si no hay variantes de producto, no se muestra el selector
        if (!hasProductVariants(repair.variants, repair.template_name)) {
            return <></>;
        }

        return (
            <Picker style={{ height: 40, width: '80%', maxWidth: 500 }} onValueChange={(_, index) => {

                const newID = repair.variants[index].id;
                const newIDS = without([...selectedRepairIDS, newID], ...repair.variants.filter((_, _index) => index != _index).map(({ id }) => id));

                setSelectedRepairsIDS([...new Set(newIDS)]);
            }}>
                {repair.variants.map((variant, i) => <Picker.Item key={i} label={`${variant.display_name} ${variant.price_info.price_with_tax_label}`} value={variant.id} />)}
            </Picker>
        )
    }

    const body = (
        <View style={styles.container}>
            {partsData.map((repair, index) => {
                return (
                    <View style={{ width: '100%', alignItems: 'center' }}>
                        <ModelCard
                            key={index}
                            repairName={repair.template_name}
                            repairPrice={repair?.variants[0]?.price_info?.price_with_tax_label}
                            selected={selectionStatuses[index]}
                            onPress={() => {
                                const newStatuses = [...selectionStatuses];
                                newStatuses[index] = !selectionStatuses[index];

                                // Uncheck Free diagnostic
                                newStatuses[selectionStatuses.length - 1] = false;

                                setSelectionStatuses(newStatuses);

                                // Si seleccionamos, ponemos por defecto siempre la primera variante
                                if (newStatuses[index] == true) {
                                    setSelectedRepairsIDS([...selectedRepairIDS, repair.variants[0].id]);
                                }
                                else {
                                    const newIDS = without(selectedRepairIDS, ...repair.variants.map(({ id }) => id));
                                    setSelectedRepairsIDS(newIDS);
                                }
                            }}
                        />

                        {selectionStatuses[index] && renderVariantPicker(repair)}
                    </View>
                )
            })}

            <ModelCard
                repairName={"No lo tengo claro/Diagnóstico gratuito"}
                repairPrice={'Gratis'}
                selected={selectionStatuses[selectionStatuses.length - 1]}
                onPress={() => {
                    const _index = selectionStatuses.length - 1;

                    const newStatuses = new Array(partsData.length).fill(false);

                    newStatuses[_index] = !selectionStatuses[_index];
                    setSelectionStatuses(newStatuses);
                    setSelectedRepairsIDS([]);
                }}
            />

        </View>
    );

    return renderLogic(isLoading, body);
});

const styles = {
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default DeviceRepairs;
