import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import Icon from '@expo/vector-icons/FontAwesome5';

import {
    PRIMARY_COLOR, SECONDARY_COLOR,
    FONT_COLOR_PRIMARY, FONT_COLOR_SECONDARY
} from '../colors/colors';

const HintBox = ({ text, style }) => {

    const boxStyle = {
        width: '100%',
        maxWidth: 500,
        borderRadius: 10,
        margin: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: 20
    }

    return (
        <View style={[boxStyle, style]}>
            <Icon 
                style={{ position: 'absolute', top: 10, left: 10 }} 
                name='lightbulb' 
                size={12} 
                color={'#000'}
            />
            <Text>{text}</Text>
        </View>
    )
}


export default HintBox;
