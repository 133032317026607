import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import Icon from '@expo/vector-icons/FontAwesome5';
import { NavbarHeights } from '../Structures';
import { PRIMARY_COLOR } from '../colors/colors';
import { ODOO_HOST } from '../config';

const Stepper = ({ stepNum, isMobileViewPort }) => {

    let barProgressFlex = 0;
    let barBGFlex = 0;

    if (stepNum == 1) {
        barProgressFlex = 0.05;
        barBGFlex = 1;
    }
    else if (stepNum == 2) {
        barProgressFlex = 1 + 0.25;
        barBGFlex = 2;
    }
    else if (stepNum == 3) {
        barProgressFlex = 2;
        barBGFlex = 1;
    }
    else if (stepNum == 4) {
        barProgressFlex = 1;
        barBGFlex = 0;
    }

    return (
        <View style={{ width: isMobileViewPort ? '100%' : 450 }}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <View style={{ flex: barProgressFlex, backgroundColor: '#1da0a7', height: 10 }} />
                <View style={{ flex: barBGFlex, backgroundColor: '#EDEDED', height: 10 }} />
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text>Dispositivo</Text>
                <Text>Reparación</Text>
                <Text>Detalles</Text>
                <Text>Resumen</Text>
            </View>
        </View>
    )
}

const NavBar = ({ stepNum, onChatPress, onCartPress, viewPortWidth }) => {

    function getHeightForNavbar() {
        return viewPortWidth > NavbarHeights.Breakpoint ? NavbarHeights.DesktopScreen : NavbarHeights.MobileScreen;
    }

    function isMobileViewPort() {
        return viewPortWidth <= NavbarHeights.Breakpoint;
    }

    const styles = StyleSheet.create({
        navbar: {
            height: getHeightForNavbar(),
            flexDirection: 'row',
            backgroundColor: "white",
            width: "100%",
            alignItems: "center",
            justifyContent: 'center'
        },
    });

    if (isMobileViewPort()) {

        return (
            <View style={styles.navbar}>

                <View style={{ width: '90%' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Pressable onPress={() => window.location.href = ODOO_HOST}>
                            <Image
                                style={{ height: 100, width: 150 }}
                                resizeMode="contain"
                                source={require('../assets/Fixmovilexpress.png')}
                            />
                        </Pressable>
                        <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                            <Pressable onPress={onChatPress} style={{ paddingHorizontal: 10, marginRight: 20, flexDirection: 'row' }}>
                                <Icon name='headset' size={25} color={PRIMARY_COLOR} />
                                <Text style={{ alignSelf: 'center', marginLeft: 10, fontWeight: 'bold' }}>Ayuda</Text>
                            </Pressable>
                        </View>
                    </View>

                    <Stepper isMobileViewPort={isMobileViewPort()} stepNum={stepNum} />

                </View>

            </View>
        )
    }

    return (
        <View style={styles.navbar}>
            <View style={{ alignItems: 'flex-end' }}>
                <Pressable onPress={() => window.location.href = ODOO_HOST}>
                    <Image
                        style={{ height: 100, width: 150 }}
                        resizeMode="contain"
                        source={require('../assets/Fixmovilexpress.png')}
                    />
                </Pressable>
            </View>
            <View style={{ marginHorizontal: 20 }}>
                <Stepper isMobileViewPort={isMobileViewPort()} stepNum={stepNum} />
            </View>
            <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
                <Pressable onPress={onChatPress} style={{ paddingHorizontal: 10, marginRight: 20, flexDirection: 'row' }}>
                    <Icon name='headset' size={25} color={PRIMARY_COLOR} />
                    <Text style={{ alignSelf: 'center', marginLeft: 10, fontWeight: 'bold' }}>Ayuda</Text>
                </Pressable>
            </View>
        </View>

    )
}


export default NavBar;
