


export function isCorrectPostalCode(postal_code) {

    if (postal_code.length != 5) return false;

    const code = parseInt(postal_code);

    if (!code) return false;

    return code > 1000 && code < 52999;
}

export function showErrorToast(_toast_ref, title, message) {

    _toast_ref.show(message, {
        type: 'custom_error',
        title: title,
        placement: 'top',
        duration: 4000,
        animationType: 'slide-in'
    });
}
