import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import { useToast } from 'react-native-toast-notifications';

import BoxSelector from '../components/BoxSelector';
import { BoxSelectorTypes } from '../Structures';
import { listAvailableBrands } from '../services/http/Requests';
import { showErrorToast } from '../helpers';
import { renderLogic } from '../components/Loading';

const DeviceBrand = forwardRef((props, ref) => {

    const toast = useToast();
    const [brandsData, setBrandsData] = useState([]);
    const [selectedBrandIndex, setSelectedBrandIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function getBrands() {
            const brands = await listAvailableBrands(props.selectedDeviceTypeID);
            setBrandsData(brands);
            setIsLoading(false);   
        }

        getBrands();
    }, []);

    useImperativeHandle(ref, () => ({

        async nextHandler() {
            if (selectedBrandIndex == -1) {
                showErrorToast(toast, 'Selecciona una marca', 'Selecciona la marca de tu dispositivo');
                return;
            }

            return props.onNextSubmit(brandsData[selectedBrandIndex]);
        }
    }))


    const body = (
        <View style={styles.container}>
            {brandsData.map((brand, index) => {
                return (
                    <BoxSelector
                        key={index}
                        isSelected={selectedBrandIndex == index}
                        selectorType={BoxSelectorTypes.Image}
                        brandName={brand.name}
                        image={brand.image_1920}
                        onPress={() => {
                            setSelectedBrandIndex(index);
                            props.onSelectedBrand(brandsData[index]);
                        }}
                    />
                )
            })}
        </View>
    )

    return renderLogic(isLoading, body)
    
});

const styles = {
    container: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        //backgroundColor: 'red',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default DeviceBrand;
