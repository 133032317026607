import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions, Picker } from "react-native";
import { useToast } from "react-native-toast-notifications";
import Icon from '@expo/vector-icons/FontAwesome5';


import TextInput from '../components/TextInput';
import { RadioGroup } from '../components/RadioInput';
import { CheckInputGroup } from '../components/CheckInput';

import { createContact, getStates as _getStates } from '../services/http/Requests';
import { isCorrectPostalCode } from '../helpers';

/**
 * TODO:
 * 1. Control entradas datos fiscales [OK]
 * 2. Ocultrar/mostrar/requerir ciertos campos segun metodo de envio seleccionado [OK]
 * 3. Aprovechar codigo postal [OK]
 * 4. Dar formato al form [OK]
 * 5. Alert avisando de los campos [OK]
 */

const PARTNER_TYPES = {
    Empresa: 'Empresa',
    Autonomo: 'Autonomo',
    Particular: 'Particular'
};

const MAX_LENGTH_POSTAL_CODE = 5;
const FORM_INPUT_WIDTH = 210;

export const Details = forwardRef((props, ref) => {

    const toast = useToast();

    const [states, setStates] = useState([]);

    const [name, setName] = useState('');
    const [wrongName, setWrongName] = useState(false);

    const [surname, setSurname] = useState('');
    const [wrongSurname, setWrongSurname] = useState(false);

    const [email, setEmail] = useState('');
    const [wrongEmail, setWrongEmail] = useState(false);

    const [phone, setPhone] = useState('');
    const [wrongPhone, setWrongPhone] = useState(false);

    const [pickUpAddress, setPickUpAddress] = useState('');
    const [wrongPickUpAddress, setWrongPickUpAddress] = useState(false);

    const [stateID, setStateID] = useState(); // País/Provincia
    const [selectedStateIndex, setSelectedStateIndex] = useState(0);


    const [city, setCity] = useState(''); // Poblacion
    const [wrongCity, setWrongCity] = useState(false);

    const [postalCode, setPostalCode] = useState(props.postalCode);
    const [wrongPostalCode, setWrongPostalCode] = useState(false);


    const [VAT, setVAT] = useState('');
    const [wrongVAT, setWrongVAT] = useState(false);

    const [companyName, setCompanyName] = useState('');
    const [wrongCompanyName, setWrongCompanyName] = useState(false);

    const [fiscalAddress, setFiscalAddress] = useState('');
    const [wrongFiscalAddress, setWrongFiscalAddress] = useState(false);

    const [fiscalStateID, setFiscalStateID] = useState(); // País/Provincia
    const [wrongFiscalStateID, setWrongFiscalStateID] = useState(false);

    const [fiscalCity, setFiscalCity] = useState(''); // Poblacion
    const [wrongFiscalCity, setWrongFiscalCity] = useState(false);

    const [fiscalPostalCode, setFiscalPostalCode] = useState('');
    const [wrongFiscalPostalCode, setWrongFiscalPostalCode] = useState(false);

    const [sameFiscalAddress, setSameFiscalAddress] = useState(false);


    const [selectedPartnerType, setSelectedPartnerType] = useState(PARTNER_TYPES.Particular);

    const [showInvoiceForm, setShowInvoiceForm] = useState(false);


    const styles = {
        container: {
            flex: 1,
            flexDirection: props.windowWidth < 880 ? 'column' : 'row',
            justifyContent: 'space-around',
            //backgroundColor: 'red',
            alignItems: 'center'
        },
        textInput: {
            height: 50,
            width: '100%',
            maxWidth: 400,
            minWidth: 280,
            borderRadius: 5,
            borderWidth: 0.5,
            borderColor: '#A8A8A8',
            padding: 4,
            marginVertical: '1rem'
        },
    }

    function showErrorToast(title, message) {

        toast.show(message, {
            type: 'custom_error',
            title: title,
            placement: 'top',
            duration: 4000,
            animationType: 'slide-in'
        });
    }

    useEffect(() => {

        async function getStates() {

            try {
                const states = await _getStates();

                setStates(states);
            }
            catch (err) { }
        }

        getStates();
    }, []);

    function renderStatesPicker() {

        return states.map((state, i) => {
            return (
                <Picker.Item key={i} label={state.name} value={state.id} />
            )
        })
    }

    function checkEmailFormat(_email) {

        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return regex.test(_email);
    }



    function checkFields() {

        setWrongName(false);
        setWrongSurname(false);
        setWrongEmail(false);
        setWrongPhone(false);

        setWrongPickUpAddress(false);
        setWrongPostalCode(false);
        setWrongCity(false);


        setWrongVAT(false);
        setWrongFiscalAddress(false);
        setWrongFiscalCity(false);
        setWrongFiscalPostalCode(false);
        setWrongFiscalStateID(false);
        setWrongCompanyName(false);

        let pass = true;

        if (name.length == 0) {
            setWrongName(true);
            pass = false;
        }

        if (surname.length == 0) {
            setWrongSurname(true);
            pass = false;
        }

        if (!checkEmailFormat(email)) {
            setWrongEmail(true);
            pass = false;
        }

        if (phone.length < 4) {
            setWrongPhone(true);
            pass = false;
        }

        if (props.requiresShipmentDetails) {

            if (pickUpAddress.length < 4) {
                setWrongPickUpAddress(true);
                pass = false;
            }

            if (!isCorrectPostalCode(postalCode)) {
                setWrongPostalCode(true);
                pass = false;
            }

            if (city.length < 4) {
                setWrongCity(true);
                pass = false;
            }
        }

        if (showInvoiceForm) {

            if (VAT.length == 0) {
                // Validar el VAT haciendo directamente la peticion
                setWrongVAT(true);
                pass = false;
            }

            if (fiscalAddress.length < 4) {
                setWrongFiscalAddress(true);
                pass = false;
            }

            if (!isCorrectPostalCode(fiscalPostalCode)) {
                setWrongFiscalPostalCode(true);
                pass = false;
            }

            if (fiscalCity.length < 4) {
                setWrongFiscalCity(true);
                pass = false;
            }

            if (selectedPartnerType == PARTNER_TYPES.Empresa) {
                if (companyName.length < 4) {
                    setWrongCompanyName(true);
                    pass = false;
                }
            }

        }

        return pass;
    }

    function handleFiscalAddressCheckboxChange(newState) {

        if (newState == true) {
            setFiscalAddress(pickUpAddress);
            setFiscalCity(city);
            setFiscalPostalCode(postalCode);
            setFiscalStateID(stateID);
        }
        else {
            setFiscalAddress('');
            setFiscalCity('');
            setFiscalPostalCode('');
        }

        setSameFiscalAddress(newState);
    }

    useImperativeHandle(ref, () => ({

        async nextHandler() {

            if (!checkFields()) {
                showErrorToast('Campos con errores', 'Corrige los campos marcados en rojo');
                return;
            }

            const info = await createContact({
                name: (selectedPartnerType == PARTNER_TYPES.Empresa) ? companyName : `${name} ${surname}`,
                street: fiscalAddress,
                zip: fiscalPostalCode,
                city: fiscalCity,
                state_id: fiscalStateID,
                country_id: 68, //Spain (for now)
                email: email,
                mobile: phone,
                vat: VAT,
                company_type: (selectedPartnerType == PARTNER_TYPES.Empresa) ? 'company' : 'person',
                //is_company: selectedPartnerType == PARTNER_TYPES.Empresa
            });

            if (info?.status == 'incorrect_vat') {
                showErrorToast('CIF/NIF/NIE incorrecto', 'Asegúrate de que el CIF/NIF/NIE sea correcto');
                setWrongVAT(true);
                return;
            }

            if (props.selectedShipmentInfo) {

                const { address, postal_code, city, state } = props.selectedShipmentInfo.ship_address_info;

                return props.onNextSubmit({
                    contact_id: info.contact_id,
                    ship_address_info: {
                        address,
                        postal_code,
                        city,
                        state
                    }
                })
            }

            props.onNextSubmit({
                contact_id: info.contact_id,
                ship_address_info: {
                    address: pickUpAddress,
                    postal_code: postalCode,
                    city,
                    state: states[selectedStateIndex].name //(Provincia)
                }
            })
        }
    }))

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                <View>
                    <TextInput width={FORM_INPUT_WIDTH} isWrong={wrongName} value={name} onChangeText={t => setName(t)} style={{ marginRight: 10 }} title={"*Nombre"} />
                    <TextInput width={FORM_INPUT_WIDTH} isWrong={wrongSurname} value={surname} onChangeText={t => setSurname(t)} title={"*Apellidos"} />
                    <TextInput width={FORM_INPUT_WIDTH} isWrong={wrongEmail} value={email} onChangeText={t => setEmail(t)} title={"*Email"} clarification="" />
                    <TextInput width={FORM_INPUT_WIDTH} isWrong={wrongPhone} value={phone} onChangeText={t => setPhone(t)} title={"*Teléfono de contacto"} />
                    {!props.requiresShipmentDetails &&
                        <CheckInputGroup
                            style={{ marginVertical: 10 }}
                            onPress={() => setShowInvoiceForm(!showInvoiceForm)}
                            isSelected={showInvoiceForm}
                            text="Necesito factura"
                        />
                    }
                </View>
                {props.requiresShipmentDetails &&
                    <View>
                        <TextInput isWrong={wrongPickUpAddress} value={pickUpAddress} onChangeText={t => setPickUpAddress(t)} title={"*Dirección de recogida"} />
                        <TextInput maxLength={MAX_LENGTH_POSTAL_CODE} isWrong={wrongPostalCode} value={postalCode} onChangeText={t => setPostalCode(t)} title={"*Código postal"} />
                        <TextInput isWrong={wrongCity} value={city} onChangeText={t => setCity(t)} title={"*Población"} />
                        <View style={{ marginTop: 15 }}>
                            <Text>*Provincia</Text>
                            <Picker style={{ height: 35 }} selectedValue={stateID} onValueChange={(id, index) => { setStateID(id); setSelectedStateIndex(index) }}>
                                {renderStatesPicker()}
                            </Picker>
                        </View>
                        <CheckInputGroup
                            style={{ marginVertical: 10 }}
                            onPress={() => setShowInvoiceForm(!showInvoiceForm)}
                            isSelected={showInvoiceForm}
                            text="Necesito factura"
                        />
                    </View>
                }
            </View>

            {showInvoiceForm &&
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 20 }}>Facturar como: </Text>
                    <View style={{ flexDirection: 'row' }}>
                        <RadioGroup
                            onPress={() => setSelectedPartnerType(PARTNER_TYPES.Particular)}
                            isSelected={selectedPartnerType == PARTNER_TYPES.Particular}
                            text={'Particular'}
                        />
                        <RadioGroup
                            onPress={() => setSelectedPartnerType(PARTNER_TYPES.Autonomo)}
                            isSelected={selectedPartnerType == PARTNER_TYPES.Autonomo}
                            text={'Autónomo'}
                            style={{ marginLeft: 30 }}
                        />
                        <RadioGroup
                            onPress={() => setSelectedPartnerType(PARTNER_TYPES.Empresa)}
                            isSelected={selectedPartnerType == PARTNER_TYPES.Empresa}
                            text={'Empresa'}
                        />
                    </View>
                    <TextInput isWrong={wrongVAT} width={FORM_INPUT_WIDTH} value={VAT} onChangeText={t => setVAT(t)} title={"CIF/NIF/NIE"} />
                    {selectedPartnerType == PARTNER_TYPES.Empresa &&
                        <TextInput isWrong={wrongCompanyName} width={FORM_INPUT_WIDTH} value={companyName} onChangeText={t => setCompanyName(t)} title={"Nombre de la empresa"} />
                    }

                    <CheckInputGroup onPress={() => handleFiscalAddressCheckboxChange(!sameFiscalAddress)} isSelected={sameFiscalAddress} text="Utilizar misma dirección de envío como facturación" />
                    <TextInput isWrong={wrongFiscalAddress} width={FORM_INPUT_WIDTH} disabled={sameFiscalAddress} value={fiscalAddress} onChangeText={t => setFiscalAddress(t)} title={"Dirección de facturación"} />
                    <TextInput isWrong={wrongFiscalPostalCode} width={FORM_INPUT_WIDTH} maxLength={MAX_LENGTH_POSTAL_CODE} disabled={sameFiscalAddress} value={fiscalPostalCode} onChangeText={t => setFiscalPostalCode(t)} title={"*Código postal"} />
                    <TextInput isWrong={wrongFiscalCity} width={FORM_INPUT_WIDTH} disabled={sameFiscalAddress} value={fiscalCity} onChangeText={t => setFiscalCity(t)} title={"*Población"} />
                    <View style={{ marginTop: 15 }}>
                        <Text>*Provincia</Text>
                        <Picker style={{ height: 35, width: FORM_INPUT_WIDTH }} enabled={!sameFiscalAddress} selectedValue={fiscalStateID} onValueChange={id => setFiscalStateID(id)}>
                            {renderStatesPicker()}
                        </Picker>
                    </View>
                </View>
            }
        </View>
    );
});



