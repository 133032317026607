
import axios from 'axios';

import { BACKEND_HOST } from '../../config';

export const getStates = async () => {

    try {
        const { data } = await axios.get(`${BACKEND_HOST}/odoo/getStates`);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const getPostalCodes = async () => {

    try {
        const { data } = await axios.get(`${BACKEND_HOST}/odoo/getPostalCodes`);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const listAvailableBrands = async selected_device_type_id => {

    try {
        const { data } = await axios.get(`${BACKEND_HOST}/odoo/listAvailableBrands?selected_device_type_id=${selected_device_type_id}`);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const listAvailableDeviceTypes = async () => {

    try {
        const { data } = await axios.get(`${BACKEND_HOST}/odoo/listAvailableDeviceTypes`);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const listAvailableModels = async selected_brand_id => {

    try {
        const { data } = await axios.get(`${BACKEND_HOST}/odoo/listAvailableModels?selected_brand_id=${selected_brand_id}`);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const listPartsForModelID = async selected_model_id => {

    try {
        const { data } = await axios.get(`${BACKEND_HOST}/odoo/listPartsForModelID?selected_model_id=${selected_model_id}`);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const listColorsForPartsIDS = async (selected_model_id, selected_parts_ids) => {

    try {
        const url = `${BACKEND_HOST}/odoo/listColorsForPartsIDS?selected_model_id=${selected_model_id}&${selected_parts_ids.map((n, index) => `selected_parts[${index}]=${n}`).join('&')}`;
       
        const { data } = await axios.get(url);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const getSummary = async (selected_parts_ids) => {

    try {
        const url = `${BACKEND_HOST}/odoo/summary?${selected_parts_ids.map((n, index) => `selected_parts[${index}]=${n}`).join('&')}`;
       
        const { data } = await axios.get(url);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const getTotalSummary = async (selected_parts_ids, selected_optional_products_ids, selected_repair_option_id) => {

    try {
        const url = `
        ${BACKEND_HOST}/odoo/totalSummary`;
       
        const { data } = await axios.get(url, {
            params: {
                selected_parts_ids,
                selected_optional_products_ids,
                selected_repair_option_id
            }
        });

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const listOptionalProducts = async (selected_parts_ids) => {

    try {
        const url = `${BACKEND_HOST}/odoo/listOptionalProducts?${selected_parts_ids.map((n, index) => `selected_parts[${index}]=${n}`).join('&')}`;
       
        const { data } = await axios.get(url);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const createContact = async (contact_info) => {

    try {
        const url = `${BACKEND_HOST}/odoo/createContact`;
       
        const { data } = await axios.post(url, { contact_info });

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const createSaleOrder = async (odoo_contact_id, product_ids, client_notes) => {

    try {
        const url = `${BACKEND_HOST}/odoo/createSaleOrder`;
       
        const { data } = await axios.post(url, { odoo_contact_id, product_ids, client_notes });

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const validateVAT = async (vat) => {

    try {
        const url = `${BACKEND_HOST}/odoo/validateVAT`;
       
        const { data } = await axios.post(url, { vat });

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const listAvailableDaysForRepairment = async () => {

    try {
        const url = `${BACKEND_HOST}/calendar/listAvailableDays`;
       
        const { data } = await axios.get(url);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const getShippingOptions = async () => {

    try {
        const url = `${BACKEND_HOST}/odoo/getShippingOptions`;
       
        const { data } = await axios.get(url);

        return data;
    }
    catch(err) {
        throw err;
    }
}

export const getAvailableTimeSlots = async (day_ts) => {

    try {
        const url = `${BACKEND_HOST}/calendar/getAvailableTimeSlots?day_ts=${day_ts}`;
       
        const { data } = await axios.get(url);

        return data;
    }
    catch(err) {
        throw err;
    }
}
