
import { View, Pressable, Text } from "react-native";
import { PRIMARY_COLOR } from "../colors/colors";

const CheckInput = ({ isSelected, style }) => {

    return (
        <View
            style={{ 
                borderWidth: 1, 
                width: 15, 
                height: 15, 
                backgroundColor: isSelected ? PRIMARY_COLOR : 'transparent',
                ...style
            }}
        />
    )
}

export const CheckInputGroup = ({ isSelected, text, onPress, style }) => {
    return (
        <Pressable style={style} onPress={onPress}>
            <View style={{ flexDirection: 'row', marginHorizontal: 10 }}>
                <CheckInput isSelected={isSelected} style={{ marginRight: 5, marginTop: 3 }} />
                <Text>{text}</Text>
            </View>
        </Pressable>
    )
}

export default CheckInput;
