import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import Icon from '@expo/vector-icons/FontAwesome5';
import { TextInput as TextInputWeb } from 'react-native-web';
import { PRIMARY_COLOR } from '../colors/colors';

export const TextInput = ({ placeholder, title, clarification, style, onChangeText, value, disabled, isWrong, maxLength, width }) => {

    const styles = {
        container: {
            marginVertical: 10
        },
        textInput: {
            height: 35,
            width: width || '100%',
            borderRadius: 5,
            borderWidth: 0.5,
            borderColor: !isWrong ? '#A8A8A8': 'red',
            padding: 4,
            outlineColor: PRIMARY_COLOR,
            backgroundColor: disabled ? '#d4d5d6': '#fff'
        },
    }

    return (
        <View style={[styles.container, style]}>
            <Text style={{ marginBottom: 5 }}>{title}</Text>
            <TextInputWeb 
                maxLength={maxLength}
                value={value} 
                onChangeText={onChangeText} 
                placeholder={placeholder} 
                style={styles.textInput} 
                disabled={disabled}
            />
            <Text>{clarification}</Text>
        </View>
    );
}


export default TextInput;
