
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import { useEffect, useRef, useState } from 'react';
import { ToastProvider } from 'react-native-toast-notifications'

import Navbar from './components/Navbar';
import NavButtons from './components/NavButtons';
import { PostalCode } from './screens/PostalCode';
import DeviceBrand from './screens/DeviceBrand';
import DeviceType from './screens/DeviceType';
import DeviceModel from './screens/DeviceModel';
import DeviceRepairs from './screens/DeviceRepairs';
import DaySelector from './screens/DaySelector';
import TimeSlotSelector from './screens/TimeSlotSelector';
import PickDeviceMethods from './screens/PickDeviceMethods';
import Summary from './screens/Summary';
import { Details } from './screens/Details';
import Checkout from './screens/Checkout';
import { ScrollView } from 'react-native-web';
import { NavbarHeights } from './Structures';

const { ODOO_HOST } = require('./config');


export default function App() {

  const [homeRepairmentAvailable, setHomeRepairmentAvailable] = useState(false);
  const [selectedPostalCode, setSelectedPostalCode] = useState('');

  const [height, setHeight] = useState(Dimensions.get('window').height);
  const [width, setWidth] = useState(Dimensions.get('window').width);

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [selectedDeviceType, setSelectedDeviceType] = useState({});
  const [selectedModel, setSelectedModel] = useState({});
  const [selectedRepairsIDS, setselectedRepairsIDSIDS] = useState([]);
  const [selectedOptionalProducts, setSelectedOptionalProducts] = useState([]);
  const [selectedShipmentInfo, setSelectedShipmentInfo] = useState(null);

  const [selectedRepairMethod, setSelectedRepairMethod] = useState({});

  const [selectedDayTimestamp, setSelectedDayTimestamp] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});

  const [jumpBackToRepairMethodScreen, setJumpBackToRepairMethodScreen] = useState(false);
  const [odooContactID, setOdooContactID] = useState(null);

  const deviceBrandRef = useRef();
  const postalCodeRef = useRef();
  const deviceTypeRef = useRef();
  const modelRef = useRef();
  const deviceRepairsRef = useRef();
  const detailsRef = useRef();
  const daySelectorRef = useRef();
  const timeSlotSelectorRef = useRef();
  const pickDeviceMethodsRef = useRef();
  const checkoutRef = useRef();

  const ScreensConfig = [
    {
      nextHandler: () => postalCodeRef.current.nextHandler(),
      component: <PostalCode
        ref={postalCodeRef}
        onNextSubmit={info => {
          setHomeRepairmentAvailable(info.home_repair_available);
          setSelectedPostalCode(info.postal_code);
          goToNextScreen();
        }}
      />,
      title: '¿Cuál es tu código postal?',
      prevIndex: 0,
      nextIndex: 1,
      stepProgressNum: 1
    },
    {
      nextHandler: () => deviceTypeRef.current.nextHandler(),
      component: <DeviceType
        ref={deviceTypeRef}
        onSelectedDeviceType={device_type => setSelectedDeviceType(device_type)}
        onNextSubmit={goToNextScreen}
      />,
      title: '¿Qué necesitas reparar?',
      prevIndex: 0,
      nextIndex: 2,
      stepProgressNum: 1
    },
    {
      nextHandler: () => deviceBrandRef.current.nextHandler(),
      component: <DeviceBrand
        ref={deviceBrandRef}
        selectedDeviceTypeID={selectedDeviceType.id}
        onSelectedBrand={brand => setSelectedBrand(brand)}
        onNextSubmit={goToNextScreen}

      />,
      title: '¿Cuál es la marca de tu dispositivo?',
      prevIndex: 1,
      nextIndex: 3,
      stepProgressNum: 1
    },
    {
      nextHandler: () => modelRef.current.nextHandler(),
      component: <DeviceModel
        ref={modelRef}
        selectedBrandID={selectedBrand.id}
        onSelectedModel={model => setSelectedModel(model)}
        onNextSubmit={goToNextScreen}

      />,
      title: '¿Qué modelo tienes?',
      prevIndex: 2,
      nextIndex: 4,
      stepProgressNum: 1
    },
    {
      nextHandler: () => deviceRepairsRef.current.nextHandler(),
      component: <DeviceRepairs
        ref={deviceRepairsRef}
        selectedModelID={selectedModel.id}
        onNextSubmit={repairs => {
          setselectedRepairsIDSIDS(repairs);
          goToNextScreen();
        }}
      />,
      title: `¿Qué reparación necesita tu ${selectedModel.name}?`,
      prevIndex: 3,
      nextIndex: 5,
      stepProgressNum: 2
    },
    {
      component: <Summary
        selectedBrandName={selectedBrand.name}
        selectedModelName={selectedModel.name}
        selectedRepairIDS={selectedRepairsIDS}
        onChangedOptionalProduct={optional_products => setSelectedOptionalProducts(optional_products)}

      />,
      title: 'Resumen de tu pedido',
      prevIndex: 4,
      nextIndex: 6,
      stepProgressNum: 2
    },
    {
      nextHandler: () => pickDeviceMethodsRef.current.nextHandler(),
      component: <PickDeviceMethods
        ref={pickDeviceMethodsRef}
        homeRepairmentAvailable={homeRepairmentAvailable}
        onSelectedRepairMethod={repair_method => setSelectedRepairMethod(repair_method)}
        onNextSubmit={(info) => {

          if (!selectedRepairMethod.can_be_agended) {
            setJumpBackToRepairMethodScreen(true);
          }
          else {
            setJumpBackToRepairMethodScreen(false);
          }

          setSelectedShipmentInfo(info);

          const screenIndex = ScreensConfig[currentScreenIndex].nextIndex;
          setCurrentScreenIndex(selectedRepairMethod.can_be_agended ? screenIndex : screenIndex + 2);
        }}

      />,
      title: 'Elige la opción de reparación que más te convenga',
      prevIndex: 5,
      nextIndex: 7,
      stepProgressNum: 3
    },
    {
      nextHandler: () => daySelectorRef.current.nextHandler(),
      component: <DaySelector
        ref={daySelectorRef}
        onSelectedDayTimestamp={day_ts => setSelectedDayTimestamp(day_ts)}
        onNextSubmit={goToNextScreen}

      />,
      title: '¿Cuándo quieres que reparemos tu equipo?',
      prevIndex: 6,
      nextIndex: 8,
      stepProgressNum: 3
    },
    {
      nextHandler: () => timeSlotSelectorRef.current.nextHandler(),
      component: <TimeSlotSelector
        ref={timeSlotSelectorRef}
        day_ts={selectedDayTimestamp}
        onSelectedTimeSlot={slot => setSelectedTimeSlot(slot)}
        onNextSubmit={goToNextScreen}
      />,
      title: '¿Cuándo quieres que reparemos tu equipo?',
      prevIndex: 7,
      nextIndex: 9,
      stepProgressNum: 3
    },
    {
      nextHandler: () => detailsRef.current.nextHandler(),
      component: <Details
        windowWidth={width}
        ref={detailsRef}
        requiresShipmentDetails={selectedRepairMethod.requires_shipment_details}
        postalCode={selectedPostalCode}
        selectedShipmentInfo={selectedShipmentInfo}
        onNextSubmit={(info) => {
          setSelectedShipmentInfo(info);
          setOdooContactID(info.contact_id);
          goToNextScreen();
        }}

      />,
      title: 'Tus datos',
      prevIndex: 8,
      nextIndex: 10,
      stepProgressNum: 3
    },
    {
      nextHandler: () => checkoutRef.current.nextHandler(),
      component: <Checkout
        ref={checkoutRef}
        odooContactID={odooContactID}
        selectedBrandName={selectedBrand.name}
        selectedModelName={selectedModel.name}
        selectedRepairIDS={selectedRepairsIDS}
        selectedRepairOption={selectedRepairMethod}
        selectedOptionalProductsIDS={selectedOptionalProducts.map(({ id }) => id)}
        selectedTimeSlot={selectedTimeSlot}
        selectedShipmentInfo={selectedShipmentInfo}

      />,
      title: 'Finalizar pedido',
      prevIndex: 9,
      nextIndex: 10,
      stepProgressNum: 4
    }
  ];

  function goToNextScreen() {
    setCurrentScreenIndex(ScreensConfig[currentScreenIndex].nextIndex);
  }

  function getHeightForNavbar() {
    return width > NavbarHeights.Breakpoint ? NavbarHeights.DesktopScreen : NavbarHeights.MobileScreen;
  }

  useEffect(() => {

    const handleChange = ({ window }) => {
      setHeight(window.height);
      setWidth(window.width);
    };

    const subscription = Dimensions.addEventListener('change', handleChange);

    return () => {
      subscription.remove();
    };
  }, [height, width]);

  return (
    <ToastProvider renderType={{
      custom_error: (toast) => (
        <View
          style={{
            maxWidth: "85%",
            paddingHorizontal: 15,
            paddingVertical: 10,
            backgroundColor: "#fff",
            marginVertical: 4,
            borderRadius: 8,
            borderLeftColor: "red",
            borderLeftWidth: 6,
            justifyContent: "center",
            paddingLeft: 16,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: "#333",
              fontWeight: "bold",
            }}
          >
            {toast.title}
          </Text>
          <Text style={{ color: "#a3a3a3", marginTop: 2 }}>{toast.message}</Text>
        </View>
      )
    }}>
      <View style={styles.container}>
        <Navbar
          viewPortWidth={width}
          stepNum={ScreensConfig[currentScreenIndex].stepProgressNum}
          onCartPress={() => {}}
          onChatPress={() => window.open(`${ODOO_HOST}/helpdesk/atencion-al-cliente-1?contact_form=1`)}
        />

        <View style={styles.body}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{ScreensConfig[currentScreenIndex].title}</Text>
          </View>

          <ScrollView style={{ maxHeight: height - 150 - getHeightForNavbar() }} contentContainerStyle={styles.screenContainer}>
            {ScreensConfig[currentScreenIndex].component}
          </ScrollView>

          <View style={styles.navButtonsContainer}>
            <NavButtons
              btnBackInvisible={ScreensConfig[currentScreenIndex].prevIndex == currentScreenIndex}
              btnNextInvisible={false}
              nextLabel={currentScreenIndex == ScreensConfig.length - 1 ? 'Ir al presupuesto': 'Siguiente'}
              onBackPress={() => {
                if (currentScreenIndex == ScreensConfig.length - 2) {
                  if (jumpBackToRepairMethodScreen) {
                    return setCurrentScreenIndex(ScreensConfig.length - 5);
                  }
                }
                setCurrentScreenIndex(ScreensConfig[currentScreenIndex].prevIndex);
              }}
              btnNextDisabled={false}
              onNextPress={() => {
                if ('nextHandler' in ScreensConfig[currentScreenIndex]) {
                  ScreensConfig[currentScreenIndex].nextHandler?.()
                }
                else {
                  goToNextScreen()
                }
              }}
            />
          </View>
        </View>
      </View>
    </ToastProvider>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F8F8F8'
  },
  title: {
    fontSize: 20
  },
  body: {
    flex: 1
  },
  screenContainer: {
    //flex: 1,
    padding: 10,
    flexGrow: 1
    //backgroundColor: 'red'
  },
  navButtonsContainer: {
    height: 100,
    //backgroundColor: 'blue'
  },
  titleContainer: {
    height: 50,
    margin: 10,
    //backgroundColor: 'green',
    alignItems: 'center',
    justifyContent: 'center'
    //backgroundColor: 'blue'
  }
});
