import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Linking } from "react-native";
import { useToast } from 'react-native-toast-notifications';
import moment from 'moment';
moment.locale('es');

import { createSaleOrder as _createSaleOrder } from '../services/http/Requests';
import HintBox from '../components/HintBox';
import { TextInput } from 'react-native-web';
import { renderLogic } from '../components/Loading';

import {
    getTotalSummary as _getTotalSummary
} from '../services/http/Requests';

const renderRepairOption = (selectedRepairMethod, selectedTimeSlot, selectedShipmentInfo) => {

    const price = selectedRepairMethod.price_info;

    const { address, postal_code, city } = selectedShipmentInfo.ship_address_info;

    const state = selectedShipmentInfo.ship_address_info?.state || '';

    return (
        <View style={styles.summaryCard}>
            <Text style={{ fontSize: 18, marginBottom: 5 }}>Opción de reparación:</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text>{selectedRepairMethod.title}</Text>
                <Text>{price.base_price > 0 ? price.price_with_tax_label : 'GRATIS'}</Text>
            </View>
            {selectedTimeSlot?.start_ts &&
                <View>
                    <Text style={{ marginTop: 10, fontWeight: 'bold' }}>Fecha: </Text>
                    <Text>{moment.unix(selectedTimeSlot.start_ts).format('dddd DD [de] MMMM HH:mm')}</Text>
                </View>
            }
            <Text style={{ fontStyle: 'italic', marginTop: 10 }}>{selectedRepairMethod.description}</Text>
            <View style={{ marginTop: 10 }}>
                <Text style={{ fontWeight: 'bold' }}>Dirección: </Text>
                <Text>{address} {postal_code} {city} {state}</Text>
            </View>
        </View>
    )
}

export const Checkout = forwardRef((props, ref) => {

    const toast = useToast();
    const [summary, setSummary] = useState(null);
    const [clientNotes, setClientNotes] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function getTotalSummary() {

            const total_summary = await _getTotalSummary(
                props.selectedRepairIDS,
                props.selectedOptionalProductsIDS,
                props.selectedRepairOption.id
            );

            setSummary(total_summary);
            setIsLoading(false);
        }

        getTotalSummary();
    }, []);


    useImperativeHandle(ref, () => ({

        async nextHandler() {

            const info = await createSaleOrder();
        }
    }))

    async function createSaleOrder() {

        if(isLoading) {
            return;
        }

        const { address, postal_code, city, state } = props.selectedShipmentInfo.ship_address_info;

        const repair_metadata = {
            client_notes: clientNotes,
            location: `${address}, ${postal_code}, ${city}, ${state}`,
            start_ts: props.selectedTimeSlot.start_ts,
            end_ts: props.selectedTimeSlot.end_ts
        };

        try {
            const url = await _createSaleOrder(
                props.odooContactID,
                [
                    ...summary.repairs.map(({ product_id }) => product_id),
                    ...summary.cross_sell_products.map(({ id }) => id),
                    summary.selected_repair_option.id
                ].map(id => { return { id } }),
                repair_metadata
            );

            if (url?.portal_url) {
                location.href = url.portal_url;
            }
            else {
                // showError
            }

        }
        catch (err) {
            throw err;
        }
    }

    function renderRepairs() {

        if (!summary?.repairs) {
            return <></>
        }

        return summary.repairs.map((product, i) => {
            return (
                <View key={i} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>{product.product_name}</Text>
                    <Text>{product.price_info.price_with_tax_label}</Text>
                </View>
            )
        })
    }

    function renderOptionalProducts() {

        if (!summary?.cross_sell_products) {
            return <></>
        }

        return summary.cross_sell_products.map((product, i) => {
            return (
                <View key={i} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>{product.display_name}</Text>
                    <Text>{product.price_info.price_with_tax_label}</Text>
                </View>
            )
        })
    }

    const body = (

        <View style={styles.container}>
            <HintBox text={"Confirma que los datos de tu reparación que te mostramos a continuación son correctos"} />

            <View style={styles.summaryCard}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Marca</Text>
                    <Text>{props.selectedBrandName}</Text>
                </View>
            </View>
            <View style={styles.summaryCard}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Modelo</Text>
                    <Text>{props.selectedModelName}</Text>
                </View>
            </View>
            <View style={styles.summaryCard}>
                <Text style={{ fontSize: 18, marginBottom: 5 }}>Reparación:</Text>
                {renderRepairs()}
                <HintBox style={{ alignSelf: 'center', backgroundColor: '#D2FFEE' }} text={"Dispondrás de 1 año completo de garantía y 6 meses en el caso de reemplazo de baterías."} />
            </View>

            {summary?.cross_sell_products?.length > 0 &&
                <View style={styles.summaryCard}>
                    <Text style={{ fontSize: 18, marginBottom: 5 }}>Complementos:</Text>
                    {renderOptionalProducts()}
                </View>
            }
            {summary?.selected_repair_option &&
                renderRepairOption({ ...summary?.selected_repair_option, description: props.selectedRepairOption.description }, props.selectedTimeSlot, props.selectedShipmentInfo)
            }
            <View style={{ width: '80%', maxWidth: 500, marginTop: 20, marginBottom: 10 }}>
                <Text>¿Algo más que quieras comentarnos acerca del equipo? Estado, se ha mojado, ya ha sido reparado etc. Esta información es útil para nuetros técnicos</Text>
            </View>

            <TextInput value={clientNotes} style={styles.textInput} onChangeText={(t) => setClientNotes(t)} />
            <View style={{ flexDirection: 'row', marginTop: 30 }}>
                <Text style={{ marginRight: 20, fontSize: 25 }}>Total:</Text>
                <Text style={{ fontSize: 25 }}>{summary?.total_price?.price_with_tax_label}</Text>
            </View>
        </View>

    );

    return renderLogic(isLoading, body);
});

const styles = {
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    summaryCard: {
        width: '80%',
        maxWidth: 500,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#A8A8A8',
        backgroundColor: '#fff',
        padding: 10,
        paddingHorizontal: 20,
        margin: 10
    },
    textInput: {
        height: 100,
        width: '80%',
        maxWidth: 500,
        minWidth: 280,
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: '#A8A8A8',
        backgroundColor: '#fff',
        padding: 4
    },
}

export default Checkout;
