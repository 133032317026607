import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import Icon from '@expo/vector-icons/FontAwesome5';

import {
    PRIMARY_COLOR, SECONDARY_COLOR,
    FONT_COLOR_PRIMARY, FONT_COLOR_SECONDARY
} from '../colors/colors';

const Button = (props) => {

    let bgColor = `rgba(29, 160, 167, ${props.disabled ? 0.5: 1})`//PRIMARY_COLOR;
    let fontColor = '#fff';

    if (props.secondary) {
        bgColor = '#fff';
        fontColor = FONT_COLOR_PRIMARY;
    }

    return (
        <Pressable style={props.style} onPress={props.onPress} disabled={props.disabled}>
            <View style={[styles.container, { backgroundColor: bgColor, fontColor }]}>
                {props.icon && props.iconLeft &&
                    <Icon style={{ marginRight: 10 }} name={props.icon} size={props.iconSize || 18} color={props.iconColor || fontColor} />
                }
                <Text style={{ color: fontColor, fontSize: 18 }}>{props.label}</Text>
                {props.icon && !props.iconLeft &&
                    <Icon style={{ marginLeft: 10 }} name={props.icon} size={props.iconSize || 18} color={props.iconColor || fontColor} />
                }
            </View>
        </Pressable>
    )
}

const styles = {
    container: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        elevation: 3
        /*shadowColor: '#171717',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,*/
    }
}

export default Button;
