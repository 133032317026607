import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import Icon from '@expo/vector-icons/FontAwesome5';
import { TextInput } from 'react-native-web';
import HintBox from '../components/HintBox';

import { getPostalCodes } from '../services/http/Requests';
import { PRIMARY_COLOR } from '../colors/colors';

const MAX_LENGTH_POSTAL_CODE = 5;

export const PostalCode = forwardRef((props, ref) => {

    const [incorrectPostalCode, setIncorrectPostalCode] = useState(false);
    const [postalCode, setPostalCode] = useState('');

    function isCorrectPostalCode(postal_code) {

        if (postal_code.length != 5) return false;

        const code = parseInt(postal_code);

        if (!code) return false;

        return code > 1000 && code < 52999;
    }

    useImperativeHandle(ref, () => ({

        async nextHandler() {
            if (!isCorrectPostalCode(postalCode)) {
                return setIncorrectPostalCode(true);
            }

            setIncorrectPostalCode(false);

            const postal_codes = await getPostalCodes();

            props.onNextSubmit({
                postal_code: postalCode,
                home_repair_available: postal_codes.includes(postalCode)
            });
        }
    }))

    return (
        <View style={styles.container}>
            <HintBox text={"Introduce tu código postal para mostrarte las opciones de reparación disponibles en tu zona"} />
            <TextInput
                value={postalCode}
                maxLength={MAX_LENGTH_POSTAL_CODE}
                placeholder="Código postal"
                style={styles.textInput}
                onChangeText={(postal_code) => setPostalCode(postal_code)}
            />
            {incorrectPostalCode &&
                <Text style={{ color: 'red', fontWeight: 'bold' }}>El código postal no es correcto. Por favor, revísalo</Text>
            }
        </View>
    );
});

const styles = {
    container: {
        flex: 1,
        //backgroundColor: 'red',
        alignItems: 'center'
    },
    textInput: {
        height: 50,
        width: '100%',
        maxWidth: 400,
        minWidth: 280,
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: '#A8A8A8',
        placeholderTextColor: '#A8A8A8',
        backgroundColor: '#fff',
        outlineColor: PRIMARY_COLOR,
        padding: 4,
        marginVertical: '1rem'
    },
}
