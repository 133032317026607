
export const BoxSelectorTypes = {
    Image: 'image',
    Icon: 'icon',
    Color: 'color'
}

export const NavbarHeights = {
    DesktopScreen: 70,
    MobileScreen: 94,
    Breakpoint: 767
}

export const RepairOptions = {
    HomeRepair: 'HomeRepair', // A technician goes to the customer location and repairs it from furgo
    PickUpAndDelivery: 'PickUpAndDelivery', // Pick up device from home and return in 2-5 business days
    StoreRepair: 'StoreRepair' // Customer goes to a pidetutecnico store. His device will be repaired within 40 minutes
}