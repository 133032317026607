import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import Icon from '@expo/vector-icons/FontAwesome5';

import Button from './Button';

const NavButtons = (props) => {

    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>

            {!props.btnBackInvisible &&
                <Button
                    disabled={props.btnBackDisabled}
                    style={{ marginRight: 20, borderWidth: 1, borderRadius: 5, borderColor: '#000', backgroundColor: '#fff' }}
                    label="Atrás"
                    icon="chevron-left"
                    iconLeft
                    secondary
                    onPress={props.onBackPress}
                />
            }
            {!props.btnNextInvisible &&
                <Button
                    disabled={props.btnNextDisabled}
                    label={props.nextLabel}
                    icon="chevron-right"
                    onPress={props.onNextPress}
                />
            }
        </View>
    )
}

export default NavButtons;
