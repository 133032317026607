
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { render } from 'react-dom';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";
import { useToast } from "react-native-toast-notifications";

import {
    getAvailableTimeSlots as _getAvailableTimeSlots
} from '../services/http/Requests';


import RadioInput from '../components/RadioInput';
import { showErrorToast } from '../helpers';
import { renderLogic } from '../components/Loading';

const Card = ({ label, onPress, isSelected }) => {
    return (
        <Pressable style={{ marginVertical: 5 }} onPress={onPress}>
            <View style={{ height: 50, width: 270, borderWidth: 1, borderColor: '#A8A8A8', backgroundColor: '#fff', borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
                <RadioInput isSelected={isSelected} />
                <Text>{label}</Text>
            </View>
        </Pressable>
    )
}

const TimeSlotSelector = forwardRef((props, ref) => {

    const toast = useToast();
    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedSlotIndex, setSelectedSlotIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function getAvailableTimeSlots() {
            const slots = await _getAvailableTimeSlots(props.day_ts);

            setAvailableSlots(slots);
            setIsLoading(false);
        }

        getAvailableTimeSlots();
    }, []);

    useImperativeHandle(ref, () => ({

        async nextHandler() {
            if (selectedSlotIndex == -1) {
                showErrorToast(toast, 'Selecciona una hora', 'Selecciona una franja horaria en la que quieras que reparemos tu equipo');
                return;
            }

            return props.onNextSubmit();
            
        }
    }))

    function renderSlots() {
        return availableSlots.map((slot, i) => {
            return (
                <Card
                    key={i}
                    label={slot.slot_label}
                    isSelected={selectedSlotIndex == i}
                    onPress={() => {
                        setSelectedSlotIndex(i);
                        props.onSelectedTimeSlot({
                            start_ts: slot.start_ts,
                            end_ts: slot.end_ts
                        });
                    }}
                />
            )
        })
    }

    const body = (
        <View style={styles.container}>
            {renderSlots()}
        </View>
    )

    return renderLogic(isLoading, body)
});

const styles = {
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default TimeSlotSelector;
