import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Picker } from "react-native";
import { useToast } from "react-native-toast-notifications";

import RadioInput from '../components/RadioInput';
import { getShippingOptions as _getShippingOptions } from '../services/http/Requests';
import { showErrorToast } from '../helpers';
import { renderLogic } from '../components/Loading';


const STORE_REPAIR_ID = 9;

const PickDeviceCard = ({ title, description, selected, price_label, base_price, picker_data, onPickerChange, onPress }) => {

    const cardStyle = {
        width: '80%',
        maxWidth: 500,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#A8A8A8',
        backgroundColor: '#fff',
        margin: 10,
        padding: 10,
        alignItems: 'flex-start',
        justifyContent: 'center'
    }

    return (
        <Pressable style={cardStyle} onPress={onPress}>
            <View style={{ flexDirection: 'row', marginBottom: 20, width: '85%' }}>
                <RadioInput style={{ marginTop: 5, marginRight: 20 }} relative isSelected={selected} />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                    <Text style={{ fontSize: 18 }}>{title}</Text>
                    <Text style={{ fontSize: 18, fontWeight: 'bold' }}>{(base_price == 0) ? 'GRATIS' : price_label}</Text>
                </View>
            </View>
            <Text style={{ fontSize: 15 }}>{description}</Text>

            {picker_data && picker_data?.length > 0 &&
                <Picker style={{ height: 35, marginTop: 10, width: '100%' }} onValueChange={(_, index) => onPickerChange(index)}>
                    {
                        [
                            <Picker.Item key={0} label={'Selecciona una tienda'} value={-1} />,
                            ...picker_data.map((d, i) => <Picker.Item key={i + 1} label={d.location} value={d.id} />)
                        ]
                    }
                </Picker>
            }
        </Pressable>
    )
}

export const PickDeviceMethods = forwardRef((props, ref) => {

    const toast = useToast();
    const [shippingOptions, setShippingOptions] = useState([]);
    const [ziplosStores, setZiplosStores] = useState([]);
    const [selectedRepairIndex, setSelectedRepairIndex] = useState(-1);
    const [selectedStoreIndex, setSelectedStoreIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function getShippingOptions() {

            const opts = await _getShippingOptions();
            setShippingOptions(opts.options);
            setZiplosStores(opts.stores);
            setIsLoading(false);
        }

        getShippingOptions();
    }, []);


    useImperativeHandle(ref, () => ({

        async nextHandler() {

            if (selectedRepairIndex == -1) {
                showErrorToast(toast, 'Selecciona una opción', 'Selecciona una opción de reparación');
                return;
            }

            if (shippingOptions[selectedRepairIndex].id == STORE_REPAIR_ID && selectedStoreIndex == 0) {
                showErrorToast(toast, 'Selecciona una tienda');
                return;
            }

            if (shippingOptions[selectedRepairIndex].id == STORE_REPAIR_ID) {

                const address_parts = ziplosStores[selectedStoreIndex - 1].location.split(',');

                props.onNextSubmit({
                    ship_address_info: {
                        address: address_parts[0],
                        postal_code: address_parts[1],
                        city: address_parts[2],
                        state: ''
                    }
                });
            }
            else {
                props.onNextSubmit(null);
            }
        }
    }))

    function renderOptions() {

        return shippingOptions.map((opt, i) => {

            // Si no damos servicio a domicilio en el codigo postal. 
            // Y el tipo de envio exige ir al domicilio, no renderizamos la opcion. (No está disponible)

            if (!props.homeRepairmentAvailable && opt.requires_home_displacement) {
                return <></>;
            }

            return (
                <PickDeviceCard
                    key={i}
                    title={opt.title}
                    description={opt.description}
                    selected={selectedRepairIndex == i}
                    price_label={opt.price_info.price_with_tax_label}
                    base_price={opt.price_info.base_price}
                    picker_data={(opt.id == STORE_REPAIR_ID) && (selectedRepairIndex == i) ? ziplosStores : null}
                    onPress={() => {
                        props.onSelectedRepairMethod(shippingOptions[i]);
                        setSelectedRepairIndex(i);
                    }}
                    onPickerChange={index => setSelectedStoreIndex(index)}
                />
            )
        })
    }

    const body = (
        <View style={styles.container}>
            {renderOptions()}
        </View>
    );

    return renderLogic(isLoading, body)
});

const styles = {
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default PickDeviceMethods;
