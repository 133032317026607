import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions } from "react-native";

import { PRIMARY_COLOR } from '../colors/colors';
import { BoxSelectorTypes } from '../Structures';
import RadioInput from './RadioInput';


const BoxSelector = (props) => {

    let content = undefined;

    if (props.selectorType == BoxSelectorTypes.Image) {
        content = (
            <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                <Image
                    style={{ height: 50, width: 50 }}
                    resizeMode="contain"
                    source={`data:image/png;base64,${props.image}`}
                />

            </View>
        );
    }
    else if (props.selectorType == BoxSelectorTypes.Color) {
        content = (
            <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 5, flex: 1 }}>
                <View style={{ width: 45, height: 45, borderRadius: 50, backgroundColor: props.color }} />
            </View>
        );
    }

    return (
        <Pressable onPress={props.onPress}>
            <View style={[styles.container, { borderWidth: props.isSelected ? 2: 1, borderColor: props.isSelected ? PRIMARY_COLOR: '#A8A8A8' }]}>
                <RadioInput isSelected={props.isSelected} />
                {content}
                <Text style={{ alignSelf: 'center', marginBottom: 5 }}>{props.brandName}</Text>
            </View>
        </Pressable>
    );
}

const styles = {
    container: {
        width: 100,
        height: 100,
        borderWidth: 2,
        borderRadius: 10,
        borderColor: '#A8A8A8',
        margin: 10,
        backgroundColor: '#fff'
    }
}

export default BoxSelector;
