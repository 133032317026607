import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View, Dimensions, ScrollView } from "react-native";

import CheckInput from '../components/CheckInput';
import HintBox from '../components/HintBox';

import { 
    getSummary as _getSummary, 
    listOptionalProducts, 
    getTotalSummary as _getTotalSummary
} from '../services/http/Requests';
import { renderLogic } from '../components/Loading';

const CrossSellProduct = ({ price, image, title, selected, onPress }) => {

    const style = {
        //height: 120,
        //width: '80%',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#A8A8A8',
        marginHorizontal: 10,
        backgroundColor: '#fff',
        padding: 10,
        margin: 10
    }

    return (
        <Pressable style={style} onPress={onPress}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <CheckInput isSelected={selected} />
                <Text style={{ fontWeight: 'bold' }}>{price}</Text>
            </View>
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Image
                    style={{ height: 50, width: 50 }}
                    resizeMode="contain"
                    source={`data:image/png;base64,${image}`}
                />
            </View>

            <Text style={{ marginTop: 10 }}>{title}</Text>

        </Pressable>
    )
}

export const Summary = (props) => {

    const [summary, setSummary] = useState(null);
    const [optionalProducts, setOptionalProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function getSummaryAndOptionalProducts() {

            let [_summary, optional_products] = await Promise.all([
                _getSummary(props.selectedRepairIDS),
                listOptionalProducts(props.selectedRepairIDS)
            ]);
            
            for(let opt_prod of optional_products) {
                opt_prod.is_selected = false;
            }

            setSummary(_summary);
            setOptionalProducts(optional_products);
            setIsLoading(false);
        }

        getSummaryAndOptionalProducts();
    }, []);

    async function getTotalSummary() {

        setIsLoading(true);

        try {
            const total_summary = await _getTotalSummary(
                props.selectedRepairIDS, 
                optionalProducts.filter(({ is_selected }) => is_selected == true).map(({ id }) => id)
            );

            // Actualizamos unicamente el total
            setSummary({ ...summary, total_price: total_summary.total_price });
        }
        catch(err) {
           
        }
        finally {
            setIsLoading(false);
        }
    }


    function renderRepairs() {

        if (!summary?.products) {
            return <></>
        }

        return summary.products.map((product, i) => {
            return (
                <View key={i} style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#fff', }}>
                    <Text>{product.product_name}</Text>
                    <Text>{product.price_info.price_with_tax_label}</Text>
                </View>
            )
        })
    }

    function renderCrossSellProducts() {

        if (!optionalProducts) {
            return <></>
        }

        return optionalProducts.map((opt_prod, i) => {
            return (
                <CrossSellProduct
                    key={i}
                    image={opt_prod.image_1920}
                    title={opt_prod.display_name}
                    price={opt_prod.price_info.price_with_tax_label}
                    selected={opt_prod.is_selected}
                    onPress={() => {
                        const updatedStatus = [...optionalProducts];
                        updatedStatus[i].is_selected = !updatedStatus[i].is_selected;
                        setOptionalProducts(updatedStatus);
                        props.onChangedOptionalProduct(updatedStatus.filter(({ is_selected }) => is_selected == true));
                        getTotalSummary();
                    }}
                />
            )
        })
    }

    const body = (
        <View style={styles.container}>
            <HintBox text={"Confirma que los datos de tu reparación que te mostramos a continuación son correctos"} />
            <View style={styles.summaryCard}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Marca</Text>
                    <Text>{props.selectedBrandName}</Text>
                </View>
            </View>
            <View style={styles.summaryCard}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>Modelo</Text>
                    <Text>{props.selectedModelName}</Text>
                </View>
            </View>
            <View style={styles.summaryCard}>
                <Text style={{ fontSize: 18, marginBottom: 5 }}>Reparación:</Text>
                {renderRepairs()}
            </View>

            {optionalProducts?.length > 0 && 
                <Text style={{ marginTop: 20 }}>Completa tu reparación añadiendo estos productos:</Text>
            }


            <ScrollView
                style={{ width: '100%' }}
                horizontal
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                
                {renderCrossSellProducts()}
            </ScrollView>

            <View style={{ flexDirection: 'row', marginTop: 30 }}>
                <Text style={{ marginRight: 20, fontSize: 25 }}>Total:</Text>
                <Text style={{ fontSize: 25 }}>{summary?.total_price?.price_with_tax_label}</Text>
            </View>
        </View>
    );

    return renderLogic(isLoading, body)
}

const styles = {
    container: {
        //flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    summaryCard: {
        width: '80%',
        maxWidth: 500,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#A8A8A8',
        backgroundColor: '#fff',
        padding: 10,
        paddingHorizontal: 20,
        margin: 10
    }
}

export default Summary;
