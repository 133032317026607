
import { View, ActivityIndicator } from 'react-native';
import { PRIMARY_COLOR } from '../colors/colors';

export const LoadingComponent = () => {
    return (
        <View style={{ flex: 1 }}>
            <ActivityIndicator
                size={50}
                animating={true}
                hidesWhenStopped={false}
                color={PRIMARY_COLOR}
                style={{ position: 'absolute', top: '50%', left: '51%' }}
            />
        </View>
    );
}

export function renderLogic(loading, mainComponent) {

    if(loading) {
        return <LoadingComponent />
    }

    return mainComponent;
}
