import React from 'react';
import { View, Text, Pressable } from "react-native";
import { ProgressSteps } from 'react-native-progress-steps';

import { PRIMARY_COLOR } from '../colors/colors';

const RadioInput = ({ isSelected, top, left, relative, style }) => {

    let _style = {
        borderRadius: 50,
        borderWidth: 1,
        width: 15,
        height: 15,
        backgroundColor: isSelected ? PRIMARY_COLOR : 'transparent',
        position: 'absolute',
        top: top || 5,
        left: left || 5,
        ...style
    }

    if (relative) {
        _style.position = 'relative';
        delete _style.top;
        delete _style.left;
    }

    return (
        <View style={_style} />
    )
}

export const RadioGroup = ({ isSelected, text, onPress }) => {
    return (
        <Pressable onPress={onPress}>
            <View style={{ flexDirection: 'row', marginHorizontal: 10 }}>
                <RadioInput relative isSelected={isSelected} style={{ marginRight: 5, marginTop: 3 }} />
                <Text>{text}</Text>
            </View>
        </Pressable>
    )
}

export default RadioInput;
